function IconNotification() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_193_9874"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_193_9874)">
        <path
          d="M14.9998 10.8335V9.16683H18.3332V10.8335H14.9998ZM15.9998 16.6668L13.3332 14.6668L14.3332 13.3335L16.9998 15.3335L15.9998 16.6668ZM14.3332 6.66683L13.3332 5.3335L15.9998 3.3335L16.9998 4.66683L14.3332 6.66683ZM4.1665 15.8335V12.5002H3.33317C2.87484 12.5002 2.48248 12.337 2.15609 12.0106C1.8297 11.6842 1.6665 11.2918 1.6665 10.8335V9.16683C1.6665 8.7085 1.8297 8.31613 2.15609 7.98975C2.48248 7.66336 2.87484 7.50016 3.33317 7.50016H6.6665L10.8332 5.00016V15.0002L6.6665 12.5002H5.83317V15.8335H4.1665ZM11.6665 12.7918V7.2085C12.0415 7.54183 12.3436 7.94808 12.5728 8.42725C12.8019 8.90641 12.9165 9.43072 12.9165 10.0002C12.9165 10.5696 12.8019 11.0939 12.5728 11.5731C12.3436 12.0522 12.0415 12.4585 11.6665 12.7918Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default IconNotification
