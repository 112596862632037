interface Props {
  width?: number
  height?: number
  color?: string
}

function IconChecked2({ width, height }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_276_307"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_276_307)">
        <path
          d="M10 14.1667C10.2361 14.1667 10.434 14.0868 10.5938 13.9271C10.7535 13.7674 10.8333 13.5694 10.8333 13.3333C10.8333 13.0972 10.7535 12.8993 10.5938 12.7396C10.434 12.5799 10.2361 12.5 10 12.5C9.76389 12.5 9.56597 12.5799 9.40625 12.7396C9.24653 12.8993 9.16667 13.0972 9.16667 13.3333C9.16667 13.5694 9.24653 13.7674 9.40625 13.9271C9.56597 14.0868 9.76389 14.1667 10 14.1667ZM10 10.8333C10.2361 10.8333 10.434 10.7535 10.5938 10.5938C10.7535 10.434 10.8333 10.2361 10.8333 10V6.66667C10.8333 6.43056 10.7535 6.23264 10.5938 6.07292C10.434 5.91319 10.2361 5.83333 10 5.83333C9.76389 5.83333 9.56597 5.91319 9.40625 6.07292C9.24653 6.23264 9.16667 6.43056 9.16667 6.66667V10C9.16667 10.2361 9.24653 10.434 9.40625 10.5938C9.56597 10.7535 9.76389 10.8333 10 10.8333ZM7.20833 16.6667H5C4.54167 16.6667 4.14931 16.5035 3.82292 16.1771C3.49653 15.8507 3.33333 15.4583 3.33333 15V12.7917L1.72917 11.1667C1.57639 11 1.45833 10.816 1.375 10.6146C1.29167 10.4132 1.25 10.2083 1.25 10C1.25 9.79167 1.29167 9.58681 1.375 9.38542C1.45833 9.18403 1.57639 9 1.72917 8.83333L3.33333 7.20833V5C3.33333 4.54167 3.49653 4.14931 3.82292 3.82292C4.14931 3.49653 4.54167 3.33333 5 3.33333H7.20833L8.83333 1.72917C9 1.57639 9.18403 1.45833 9.38542 1.375C9.58681 1.29167 9.79167 1.25 10 1.25C10.2083 1.25 10.4132 1.29167 10.6146 1.375C10.816 1.45833 11 1.57639 11.1667 1.72917L12.7917 3.33333H15C15.4583 3.33333 15.8507 3.49653 16.1771 3.82292C16.5035 4.14931 16.6667 4.54167 16.6667 5V7.20833L18.2708 8.83333C18.4236 9 18.5417 9.18403 18.625 9.38542C18.7083 9.58681 18.75 9.79167 18.75 10C18.75 10.2083 18.7083 10.4132 18.625 10.6146C18.5417 10.816 18.4236 11 18.2708 11.1667L16.6667 12.7917V15C16.6667 15.4583 16.5035 15.8507 16.1771 16.1771C15.8507 16.5035 15.4583 16.6667 15 16.6667H12.7917L11.1667 18.2708C11 18.4236 10.816 18.5417 10.6146 18.625C10.4132 18.7083 10.2083 18.75 10 18.75C9.79167 18.75 9.58681 18.7083 9.38542 18.625C9.18403 18.5417 9 18.4236 8.83333 18.2708L7.20833 16.6667Z"
          fill="#FC4B54"
        />
      </g>
    </svg>
  )
}

export default IconChecked2
