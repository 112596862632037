function IconDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M3.11127 5.62299C3.40416 5.3301 3.87904 5.3301 4.17193 5.62299L8.6416 10.0927L13.1113 5.62299C13.4042 5.3301 13.879 5.3301 14.1719 5.62299C14.4648 5.91588 14.4648 6.39076 14.1719 6.68365L9.17193 11.6837C8.87904 11.9765 8.40416 11.9765 8.11127 11.6837L3.11127 6.68365C2.81838 6.39076 2.81838 5.91588 3.11127 5.62299Z"
        fill="#C7C7C7"
      />
    </svg>
  )
}

export default IconDown
