function IconShield() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_276_405"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#539AFF" />
      </mask>
      <g mask="url(#mask0_276_405)">
        <path
          d="M8.75016 10.8332V12.0832C8.75016 12.3193 8.83002 12.5172 8.98975 12.6769C9.14947 12.8366 9.34738 12.9165 9.5835 12.9165H10.4168C10.6529 12.9165 10.8509 12.8366 11.0106 12.6769C11.1703 12.5172 11.2502 12.3193 11.2502 12.0832V10.8332H12.5002C12.7363 10.8332 12.9342 10.7533 13.0939 10.5936C13.2536 10.4339 13.3335 10.2359 13.3335 9.99984V9.1665C13.3335 8.93039 13.2536 8.73248 13.0939 8.57275C12.9342 8.41303 12.7363 8.33317 12.5002 8.33317H11.2502V7.08317C11.2502 6.84706 11.1703 6.64914 11.0106 6.48942C10.8509 6.3297 10.6529 6.24984 10.4168 6.24984H9.5835C9.34738 6.24984 9.14947 6.3297 8.98975 6.48942C8.83002 6.64914 8.75016 6.84706 8.75016 7.08317V8.33317H7.50016C7.26405 8.33317 7.06613 8.41303 6.90641 8.57275C6.74669 8.73248 6.66683 8.93039 6.66683 9.1665V9.99984C6.66683 10.2359 6.74669 10.4339 6.90641 10.5936C7.06613 10.7533 7.26405 10.8332 7.50016 10.8332H8.75016ZM10.0002 18.2498C9.90294 18.2498 9.81266 18.2429 9.72933 18.229C9.646 18.2151 9.56266 18.1943 9.47933 18.1665C7.60433 17.5415 6.11127 16.3853 5.00016 14.6978C3.88905 13.0103 3.3335 11.1943 3.3335 9.24984V5.31234C3.3335 4.96512 3.43419 4.65262 3.63558 4.37484C3.83697 4.09706 4.09739 3.89567 4.41683 3.77067L9.41683 1.89567C9.61127 1.82623 9.80572 1.7915 10.0002 1.7915C10.1946 1.7915 10.3891 1.82623 10.5835 1.89567L15.5835 3.77067C15.9029 3.89567 16.1634 4.09706 16.3647 4.37484C16.5661 4.65262 16.6668 4.96512 16.6668 5.31234V9.24984C16.6668 11.1943 16.1113 13.0103 15.0002 14.6978C13.8891 16.3853 12.396 17.5415 10.521 18.1665C10.4377 18.1943 10.3543 18.2151 10.271 18.229C10.1877 18.2429 10.0974 18.2498 10.0002 18.2498Z"
          fill="#539AFF"
        />
      </g>
    </svg>
  )
}

export default IconShield
