interface Props {
  checked: boolean
  onClick: () => void
}

function CheckBox({ checked, onClick }: Props) {
  return <div onClick={onClick}>{checked ? <Checked /> : <Default />}</div>
}

function Checked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6416 22.1533C7.92756 22.1533 5.57053 22.1533 4.10607 20.6889C2.6416 19.2244 2.6416 16.8674 2.6416 12.1533C2.6416 7.43928 2.6416 5.08225 4.10607 3.61779C5.57053 2.15332 7.92756 2.15332 12.6416 2.15332C17.3556 2.15332 19.7127 2.15332 21.1771 3.61779C22.6416 5.08225 22.6416 7.43928 22.6416 12.1533C22.6416 16.8674 22.6416 19.2244 21.1771 20.6889C19.7127 22.1533 17.3556 22.1533 12.6416 22.1533ZM16.6719 9.12299C16.9648 9.41588 16.9648 9.89076 16.6719 10.1837L11.6719 15.1837C11.379 15.4765 10.9042 15.4765 10.6113 15.1837L8.61127 13.1837C8.31838 12.8908 8.31838 12.4159 8.61127 12.123C8.90416 11.8301 9.37904 11.8301 9.67193 12.123L11.1416 13.5927L15.6113 9.12299C15.9042 8.8301 16.379 8.8301 16.6719 9.12299Z"
        fill="#3366FF"
      />
    </svg>
  )
}

function Default() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.699 1.40332H12.5842C10.2758 1.40331 8.46679 1.4033 7.05531 1.59307C5.61057 1.78731 4.47055 2.19265 3.57574 3.08746C2.68093 3.98227 2.27559 5.12229 2.08135 6.56703C1.89158 7.97851 1.89159 9.78754 1.8916 12.0959V12.2107C1.89159 14.5191 1.89158 16.3281 2.08135 17.7396C2.27559 19.1844 2.68093 20.3244 3.57574 21.2192C4.47055 22.114 5.61057 22.5193 7.05531 22.7136C8.4668 22.9033 10.2758 22.9033 12.5842 22.9033H12.699C15.0074 22.9033 16.8164 22.9033 18.2279 22.7136C19.6726 22.5193 20.8127 22.114 21.7075 21.2192C22.6023 20.3244 23.0076 19.1844 23.2019 17.7396C23.3916 16.3281 23.3916 14.5191 23.3916 12.2107V12.0959C23.3916 9.78755 23.3916 7.97851 23.2019 6.56703C23.0076 5.12229 22.6023 3.98227 21.7075 3.08746C20.8127 2.19265 19.6726 1.78731 18.2279 1.59307C16.8164 1.4033 15.0074 1.40331 12.699 1.40332ZM4.6364 4.14812C5.20605 3.57846 5.97678 3.25157 7.25518 3.07969C8.55516 2.90491 10.2634 2.90332 12.6416 2.90332C15.0198 2.90332 16.728 2.90491 18.028 3.07969C19.3064 3.25157 20.0771 3.57846 20.6468 4.14812C21.2165 4.71777 21.5434 5.48849 21.7152 6.7669C21.89 8.06688 21.8916 9.77509 21.8916 12.1533C21.8916 14.5315 21.89 16.2398 21.7152 17.5397C21.5434 18.8181 21.2165 19.5889 20.6468 20.1585C20.0771 20.7282 19.3064 21.0551 18.028 21.2269C16.728 21.4017 15.0198 21.4033 12.6416 21.4033C10.2634 21.4033 8.55516 21.4017 7.25518 21.2269C5.97678 21.0551 5.20605 20.7282 4.6364 20.1585C4.06674 19.5889 3.73985 18.8181 3.56797 17.5397C3.3932 16.2398 3.3916 14.5315 3.3916 12.1533C3.3916 9.77509 3.3932 8.06688 3.56797 6.7669C3.73985 5.48849 4.06674 4.71777 4.6364 4.14812Z"
        fill="#C7C7C7"
      />
    </svg>
  )
}

export default CheckBox
