function IconCaution() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.42626 8.96791C6.85823 4.65597 8.07422 2.5 9.99984 2.5C11.9255 2.5 13.1414 4.65597 15.5734 8.96791L15.8765 9.50522C17.8974 13.0884 18.9079 14.88 17.9946 16.19C17.0814 17.5 14.8219 17.5 10.3029 17.5H9.69678C5.1778 17.5 2.91831 17.5 2.00505 16.19C1.09179 14.88 2.10226 13.0884 4.12321 9.50523L4.42626 8.96791ZM9.99984 6.04167C10.345 6.04167 10.6248 6.32149 10.6248 6.66667V10.8333C10.6248 11.1785 10.345 11.4583 9.99984 11.4583C9.65466 11.4583 9.37484 11.1785 9.37484 10.8333V6.66667C9.37484 6.32149 9.65466 6.04167 9.99984 6.04167ZM9.99984 14.1667C10.4601 14.1667 10.8332 13.7936 10.8332 13.3333C10.8332 12.8731 10.4601 12.5 9.99984 12.5C9.5396 12.5 9.1665 12.8731 9.1665 13.3333C9.1665 13.7936 9.5396 14.1667 9.99984 14.1667Z"
        fill="#FFC33B"
      />
    </svg>
  )
}

export default IconCaution
